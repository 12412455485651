
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AdministratorNavigationVue from "@/components/administrator/navigation.vue";
import AdministratorMatchesNavigationVue from "@/components/administrator/matches/navigation.vue";
import AppAdministratorMatchesCategoriesListIndexVue from "@/views/app/administrator/matches/categories/list/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    AdministratorNavigationVue,
    AdministratorMatchesNavigationVue,
    AppAdministratorMatchesCategoriesListIndexVue,
  },
})
export default class AppAdministratorMatchesCategoriesIndexVue extends Vue {}
