
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Models
import {AppAdministratorMatchesCategoriesListIndex} from "@/models/app/administrator/matches/categories/list/index";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AppAdministratorMatchesCategoriesListFormCreateIndexVue from "@/views/app/administrator/matches/categories/list/form/create.vue";
import AppAdministratorMatchesCategoriesListFormUpdateIndexVue from "@/views/app/administrator/matches/categories/list/form/update.vue";
import AppAdministratorMatchesCategoriesListFormRemoveIndexVue from "@/views/app/administrator/matches/categories/list/form/remove.vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  components: {
    ElRow,
    ElCol,
    AppAdministratorMatchesCategoriesListFormCreateIndexVue,
    AppAdministratorMatchesCategoriesListFormUpdateIndexVue,
    AppAdministratorMatchesCategoriesListFormRemoveIndexVue,
  },
})
export default class AppAdministratorMatchesCategoriesListIndexVue extends Vue {
  isLoading: boolean | null = false;

  matchCategories: Array<AppAdministratorMatchesCategoriesListIndex> = [];

  async getMatchCategories(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/administration/match-categories`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.matchCategories = r.data;
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    await this.getMatchCategories();
  }
}
